import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowSquareLeft } from "@phosphor-icons/react";

const NotFound = () => {
  const navigate = useNavigate()

  return (
    <div className='h-lvh w-full flex items-center justify-center flex-col'>
        <p className='text-9xl font-bold mb-5'>404</p>
        <p className='text-base text-gray-600'>Maaf halaman yang anda tuju tidak tersedia saat ini.</p>

        <div className="md:flex items-center gap-2 my-5 cursor-pointer border-2 rounded-lg py-1 px-3" onClick={() => navigate(-1)}>
            <ArrowSquareLeft size={32} color="#008DD1" className='cursor-pointer hover:shadow-lg shadow-xs duration-300 transition ease-in-out'/>
            <div className="md:flex items-center gap-2 text-sm">
                <span className="text-gray-400">Kembali</span>
            </div>
        </div>
    </div>
  );
};

export default NotFound;
