import React, {useEffect, useState} from 'react';
import Jumbotron from '../../component/Jumbotron';
import InfoBar from '../../component/InfoBar';
import { useDispatch, useSelector } from 'react-redux';
import { getFileContent } from '../../store/actions/fileContentAction';
import { getFileListContent } from '../../store/actions/fileListContentAction';
import { useParams } from 'react-router-dom';
import { truncateText } from '../../utils/utils';
import { ArrowLeft, ArrowRight } from "@phosphor-icons/react";
import { CaretDown, CaretRight, CaretUp } from '@phosphor-icons/react/dist/ssr';
import PDFPreview from '../../component/PDFPreview';
import VideoPlayer from '../../component/VideoPlayer';
import AudioPlayer from '../../component/AudioPlayer';
import { Helmet } from 'react-helmet';

const Link = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { loading, data } = useSelector((state) => state.footer);
  const [collapse, setCollapse] = useState(null)
  const [content, setContent] = useState(null);

  useEffect(() => {
    if(!loading){
      if(id !== 'FAQ'){
        setContent(data?.links?.filter((a) => a.slug == id)[0])
      }else{
        setContent(data?.link_questions?.filter((a) => a.slug == id)[0])
      }
    }
  },[data])

  const toggleCollapse = (idx) => {
    setCollapse(idx == collapse ? 0 : idx)
  }


  return (
    <div>
      <Helmet>
        <title>{id + " - Direktorat SMP"}</title>
        <meta name="description" content={"Halaman " + id + " - Direktorat SMP"} />
        <meta property="og:title" content={id + " - Direktorat SMP"} />
        <meta property="og:description" content={"Halaman " + id + " - Direktorat SMP"} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
      </Helmet>
      <div className='pt-[120px] md:px-[50px] px-[10px]'>
        {id == 'FAQ' ?
          <div className='md:grid grid-cols-4'>
            <div className="col-span-2 col-start-2">
              <div className="text-2xl text-center space-y-2 flex items-center flex-col mb-10">
                <span className='font-bold'>{content?.link_title}</span>
              </div>
              {content?.footer_links?.map((item,key) => {
                return(
                  <div key={key} data-accordion={collapse == item.id} className='mb-3 border border-gray-200 rounded-lg'>
                    <h2 onClick={() => toggleCollapse(item.id)}>
                      <button type="button" className="flex items-center justify-between w-full p-3 font-medium text-gray-800 gap-3">
                        <span>{ item?.question }</span>
                        <div className='p-2'>
                          {collapse == item.id ?
                            <CaretUp size={15}/> :
                            <CaretDown size={15}/>
                          }
                        </div>
                      </button>
                    </h2>
                    <div className={`${collapse == item?.id ? 'block' : 'hidden'} text-gray-700 border-t border-gray-200`}>
                      <div className="p-5 border-gray-200">
                        <div className='mb-5'>
                          { item?.answer }
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div> :

          <div>
            <div className="text-2xl text-center space-y-2 flex items-center flex-col mb-10">
              <img src={ content?.footer_links?.[0]?.image?.url } alt="image" className='object-contain h-[100px] w-[150px]'/>
              <span className='font-bold'>{content?.link_title}</span>
            </div>
            <div className="text-base sm:text-left text-justify mb-3 text-gray-800 htmlPreview" dangerouslySetInnerHTML={{ __html: content?.footer_links?.[0]?.description }} />
          </div>
        }
      </div>
    </div>
    
  );
};

export default Link;
