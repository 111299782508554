import React, { useState, useRef } from 'react';

const AudioPlayer = ({audioLink}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);

  const togglePlay = () => {
    const audio = audioRef.current;
    setIsPlaying(!isPlaying);

    if (isPlaying) {
      audio.pause();
    } else {
      audio.play();
    }
  };

  return (
    <div>
      <audio
        ref={audioRef}
        src={audioLink}
        controls
        onClick={togglePlay}
      />
      {/* <button onClick={togglePlay}>{isPlaying ? 'Pause' : 'Play'}</button> */}
    </div>
  );
};

export default AudioPlayer;
