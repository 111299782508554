import React, { useEffect } from 'react';

const HistatsCounter = () => {
  useEffect(() => {
    window._Hasync = window._Hasync || [];
    window._Hasync.push(['Histats.start', '1,4878536,4,424,112,75,00011111']);
    window._Hasync.push(['Histats.fasi', '1']);
    window._Hasync.push(['Histats.track_hits', '']);
    window._Hasync.push(['Histats.framed_page', '']);

    const loadHistatsScript = () => {
      if (!document.getElementById('histats-script')) {
        const hs = document.createElement('script');
        hs.type = 'text/javascript';
        hs.async = true;
        hs.src = '//s10.histats.com/js15_as.js';
        hs.id = 'histats-script';
        hs.onload = () => {
          console.log('Histats script loaded successfully.');
          applyCanvasStyles();
        };
        hs.onerror = () => console.error('Error loading Histats script.');
        document.head.appendChild(hs);
      }
    };

    const applyCanvasStyles = () => {
      const histatsCanvas = document.querySelector('#histats_counter canvas');
      if (histatsCanvas) {
        histatsCanvas.style.width = '140px'; // Adjust width as needed
      } else {
        // Retry after a short delay if canvas is not yet available
        setTimeout(applyCanvasStyles, 100);
      }
    };

    loadHistatsScript();
  }, []);

  return (
    <>
      <div id="histats_counter"></div>
      <noscript>
        <a href="/" target="_blank">
          <img src="//sstatic1.histats.com/0.gif?4878536&101" alt="free hit counter script" border="0" />
        </a>
      </noscript>
    </>
  );
};

export default HistatsCounter;
