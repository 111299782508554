import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL

export const FETCH_FOOTER_REQUEST = 'FETCH_FOOTER_REQUEST';
export const FETCH_FOOTER_SUCCESS = 'FETCH_FOOTER_SUCCESS';
export const FETCH_FOOTER_FAILURE = 'FETCH_FOOTER_FAILURE';

export const fetchFooterRequest = () => ({
  type: FETCH_FOOTER_REQUEST,
});

export const fetchFooterSuccess = data => ({
  type: FETCH_FOOTER_SUCCESS,
  payload: data,
});

export const fetchFooterFailure = error => ({
  type: FETCH_FOOTER_FAILURE,
  payload: error,
});


// Async action creator using thunk
export const getFooter = () => {
  return dispatch => {
    dispatch(fetchFooterRequest());
    axios.get(`${BASE_URL}/managementfooter`)
      .then(response => {
        const data = response.data;
        dispatch(fetchFooterSuccess(data));
      })
      .catch(error => {
        dispatch(fetchFooterFailure(error.message));
      });
  };
};

