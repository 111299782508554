import React, {useEffect, useState} from 'react';
import InfoBar from '../../component/InfoBar';
import dummyInfo from '../../asset/dummy/_info';
import { Clock, ArrowSquareLeft } from "@phosphor-icons/react";
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getArticle } from '../../store/actions/articleAction';
import { formatDate } from '../../utils/utils';
import { Helmet } from 'react-helmet';


const RagamInfoDetail = () => {
  const { id, slug } = useParams();
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const data = useSelector(state => state.article.data);
  const loading = useSelector(state => state.article.loading);

  const [truncatedContent, setTruncatedContent] = useState("");

  useEffect(() => {
    if (data?.content) {
      // Create a temporary DOM element to parse the HTML
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = data?.content;
      
      // Get the first three <p> elements and their text content
      const paragraphs = tempDiv.querySelectorAll('p');
      let content = "";
      for (let i = 0; i < 3 && i < paragraphs.length; i++) {
        content += paragraphs[i].textContent + " ";
      }

      // Remove HTML entities like &nbsp; by using textContent
      const decodedContent = content.replace(/\s+/g, ' ').trim();

      // Truncate the concatenated content to 100 characters
      const truncated = decodedContent.length > 100 ? decodedContent.substring(0, 200) + '...' : decodedContent;
      setTruncatedContent(truncated);
    }
  }, [data]);

  useEffect(() => {
    dispatch(getArticle(slug));
  },[slug])

  return (
    <div>
      <Helmet>
                <title>{data?.title + " - Direktorat SMP"}</title>
                <meta name="description" content={truncatedContent} />
                <meta property="og:title" content={data?.title + " - Direktorat SMP"} />
                <meta property="og:description" content={truncatedContent} />
                <meta property="og:image" content={data?.featured_image?.url} />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="website" />
            </Helmet>
      <div className='pt-[60px]'>
        {!loading &&
          <div className="md:grid md:grid-cols-3 md:px-20 px-6 md:py-20 py-6 md:gap-20">
              <div className="col-span-2 mb-10">
                  <div className="md:flex items-center gap-2 mb-3">
                      <ArrowSquareLeft size={32} color="#008DD1" className='cursor-pointer hover:shadow-lg shadow-xs duration-300 transition ease-in-out' onClick={() => navigate(-1)}/>
                      <div className="md:flex items-center gap-2 text-sm">
                          <span className="text-gray-400">Ragam Informasi / </span>
                          <span className="text-gray-400">{id == 'article' ? 'Artikel' : (id == 'news' ? 'Berita' : 'Berbagi Cerita')} / </span>
                          <span className="text-gray-800 font-semibold">{data?.title}</span>
                      </div>
                  </div>
                  <div className="aspect-[32/15]">
                    <img src={data?.featured_image?.url} alt="" className='h-full w-full rounded-xl object-cover bg-gray-200' />
                  </div>
                  <div className="my-3 font-bold text-3xl md:pr-10">{data?.title}</div>
                  <div className="flex gap-2 items-center text-gray-800 mb-5">
                    <div className="font-normal text-sm mr-2">Oleh <span className="font-semibold text-sm uppercase">{data?.author?.name}</span></div>
                    <Clock size={20} color="#008DD1"/>
                    <div className="text-semibold text-sm text-gray-800">{formatDate(data?.created_at)}</div>
                  </div>
                  <div className="text-base sm:text-left text-justify mb-3 text-gray-800 htmlPreview" dangerouslySetInnerHTML={{ __html: data?.content }} />
              </div>
              <div className="col-span-1">
                  <InfoBar/>
              </div>
          </div>
        }
      </div>
    </div>
  );
};

export default RagamInfoDetail;
