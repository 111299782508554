// src/reducers/menu.js
import {
  FETCH_FILE_LIST_REQUEST,
  FETCH_FILE_LIST_SUCCESS,
  FETCH_FILE_LIST_FAILURE,
} from '../actions/fileListContentAction';

const initialState = {
  loadingList: false,
  dataList: [],
  errorList: '',
};

const fileListContentReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FILE_LIST_REQUEST:
      return {
        ...state,
        loadingList: true,
      };
    case FETCH_FILE_LIST_SUCCESS:
      return {
        loadingList: false,
        dataList: action.payload,
        errorList: '',
      };
    case FETCH_FILE_LIST_FAILURE:
      return {
        loadingList: false,
        dataList: [],
        errorList: action.payload,
      };
    default:
      return state;
  }
};

export default fileListContentReducer;
