import React, {useEffect, useState} from 'react';
import Jumbotron from '../../component/Jumbotron';
import dummyPustaka from '../../asset/dummy/_pustaka.js';
import { useDispatch } from 'react-redux';
import { postPengaduan } from '../../store/actions/pengaduanAction';

const PengaduanMasyarakat = () => {
    const dispatch = useDispatch();
    const header = [{
        images: 'https://s3-alpha-sig.figma.com/img/1f80/dc8e/7709b2a930008a684c3e7a51fd851df0?Expires=1720396800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=ctQIuw~yegzurYMCJytxgPZixipX9aVg42OJ1ZOwb2aFkfCbEOQlLcv5~VvuvUuBGhMOJTfUuLqZFqlwaV9gqnHBncMaqFQRFDgrmwRVG9et3Ax-fjXIEOcugIS3cRwO2V46uwuchHVx3f8WPjV-JZ6LhJMBICG~hTUXbMo8XNid281ukaPK25JOgfOUarXjSBWMzd9IJ9HzeAnrh~pkIXjWDQamaSpD66HRIF29A8qibattR8Il0DGQHI4Fhp7FcHkfMrzn4HzZCtEDEJgE3WfZrhYTEbf-wwvwDKZZnrilBu6OMOZd9BEGZ8FcXB7DcKtMGkZ9giMkYgYWVqoO3g__',
        title: 'Pengaduan Masyarakat'
    }]

    const [formData, setFormData] = useState({
        name: '',
        institute: '',
        email: '',
        phone: '',
        message: ''
    });

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [id]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(postPengaduan(formData));
        setFormData({
            name: '',
            institute: '',
            email: '',
            phone: '',
            message: ''
        })
    };

    return (
        <div className='pt-[70px] pb-[50px]'>
            <Jumbotron data={header}/>
            <div className="md:px-20 px-6 md:py-3 py-6 mt-8">
                <div className="text-xl font-semibold mb-2 text-[#008DD1]">Berpartisipasi dalam Peningkatan Layanan</div>
                <div className="text-base font-semibold mb-2 text-gray-600">Harap isi formulir di bawah ini untuk menyampaikan keluhan atau masukan terkait layanan kami!</div>
            </div>
            <div className='md:px-20 px-6 md:py-3 py-6'>
                <form onSubmit={handleSubmit} className="rounded-lg bg-[#F0F7FC] md:grid md:grid-cols-2 md:gap-6 p-6">
                    <div className="col-span-1">
                        <label for="name" class="block mb-2 text-sm font-medium text-gray-900">Nama</label>
                        <input type="text" value={formData.name} id="name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" placeholder="Nama lengkap" required onChange={handleInputChange} />
                    </div>
                    <div className="col-span-1">
                        <label for="institute" class="block mb-2 text-sm font-medium text-gray-900">Instansi</label>
                        <input type="text" value={formData.institute} id="institute" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" placeholder="Nama instansi" required onChange={handleInputChange} />
                    </div>
                    <div className="col-span-1">
                        <label for="email" class="block mb-2 text-sm font-medium text-gray-900">Email</label>
                        <input type="email" value={formData.email} id="email" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" placeholder="Masukan alamat email" required onChange={handleInputChange} />
                    </div>
                    <div className="col-span-1">
                        <label for="phone" class="block mb-2 text-sm font-medium text-gray-900">Nomor Telepon</label>
                        <input type="number" value={formData.phone} id="phone" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" placeholder="Masukan nomor telepon" required onChange={handleInputChange} />
                    </div>
                    <div className="col-span-2">
                        <label for="message" class="block mb-2 text-sm font-medium text-gray-900">Kritik atau Saran</label>
                        <textarea id="message" rows={10} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" placeholder="Tuliskan kritik atau saran anda" required onChange={handleInputChange}>
                            {formData.message}
                        </textarea>
                    </div>
                    <div className="flex">
                        <button type='submit' className='px-5 py-2.5 rounded-lg bg-[#008DD1] text-white'>Kirimkan</button>
                    </div>
                </form>
            </div>
        </div>
    );
    };

export default PengaduanMasyarakat;
