import React from 'react';
import logo from '../asset/images/logo.png';
import sponsor from '../asset/images/sponsor.png';
import HistatsCounter from './Histats';

const Footer = ({general, data}) => {

  return (
    <footer className="bg-[#016BA3]">
        <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
          <div className="md:grid gap-10 md:gap-6 md:grid-cols-4">
                <div className="col-span-2">
                    <div className="mb-3 md:pr-10">
                        <div className="md:flex mb-2">
                          <img src={data?.logo?.image?.url} alt="logo" className="h-[60px] md:mr-3"/>
                          <div>
                            <div className='font-bold text-gray-100'>{general?.tagline}</div>
                            <div className='font-normal text-sm text-gray-300 mb-3' dangerouslySetInnerHTML={{ __html: data?.description }}></div>
                            <div className="text-gray-100 font-normal text-sm mb-2">
                              {general?.address}
                            </div>
                            <div className="text-gray-100 font-normal text-sm mb-2">
                              <span className='mr-2'>Telp :</span>
                              {general?.phones?.map((item,key) => {
                                return(
                                  <span key={key}>
                                    {item?.phone_number}
                                    {(general?.phones.length > 1 && key < general?.phones.length-1) ? ', ' : '' }
                                  </span>
                                )
                              })}
                            </div>
                            <div className="text-gray-100 font-normal text-sm mb-2">
                              <span className='mr-2'>Email :</span>
                              {general?.emails?.map((item,key) => {
                                return(
                                  <span key={key}>
                                    {item?.email}
                                    {(general?.emails.length > 1 && key < general?.emails.length-1) ? ', ' : '' }
                                  </span>
                                )
                              })}
                            </div>
                            <a href={general?.map_link} target='_blank' className='text-gray-100 font-bold underline text-sm'>
                              Kunjungi Lokasi
                            </a>
                          </div>
                        </div>
                    </div>
                    <div className="flex flex-wrap gap-2 my-3">
                      {data?.linked_logo?.map((item,key) => {
                        return(
                          <img key={key} src={item?.image?.url} alt="sponsor" className="my-2 h-[60px] object-contain"/>
                        )
                      })}
                    </div>
                </div>
                <div className='col-span-1'>
                    <h2 className="mb-3 text-base font-semibold text-white dark:text-white">Tautan</h2>
                    <ul className="text-white font-normal">
                      {data?.links?.map((item,key) => {
                        return(
                          <li key={key} className="border-l-2 mb-3 border-yellow-500 pl-2">
                              <a href={`/link/${item?.slug}`} className="hover:text-yellow-500">{item?.link_title}</a>
                          </li>
                        )
                      })}
                      {data?.link_questions?.map((item,key) => {
                        return(
                          <li key={key} className="border-l-2 mb-3 border-yellow-500 pl-2">
                              <a href={`/link/${item?.slug}`} className="hover:text-yellow-500">{item?.link_title}</a>
                          </li>
                        )
                      })}
                    </ul>
                </div>
                <div className='col-span-1'>
                    <h2 className="mb-3 text-base font-semibold text-white dark:text-white">Statistik Pengunjung</h2>
                    <div>
                      <HistatsCounter />
                      {/* <table className='text-white text-sm'>
                        <tbody>
                          <tr>
                            <td>Pengguna Online</td>
                            <td>: <span className="font-semibold">100</span></td>
                          </tr>
                          <tr>
                            <td>Pengunjung hari ini</td>
                            <td>: <span className="font-semibold">200.000</span></td>
                          </tr>
                          <tr>
                            <td>Kunjungan halaman hari ini</td>
                            <td>: <span className="font-semibold">3.000.000</span></td>
                          </tr>
                          <tr>
                            <td>Total pengunjung</td>
                            <td>: <span className="font-semibold">3.200.000</span></td>
                          </tr>
                        </tbody>
                      </table> */}
                    </div>
                </div>
          </div>
          <hr className="my-6 border-gray-400 sm:mx-auto lg:my-8" />
          <div className="sm:flex sm:items-center sm:justify-center">
              <span className="text-sm text-white sm:text-center dark:text-white">
                Copyright &copy; {data?.copyright}
              </span>
          </div>
        </div>
    </footer>
  );
};

export default Footer;