import React, {useEffect, useState, useRef} from 'react';
import Jumbotron from '../../component/Jumbotron';
import InfoBar from '../../component/InfoBar';
import { useDispatch, useSelector } from 'react-redux';
import { getSingleContent } from '../../store/actions/singleContentAction';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const SingleContent = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const data = useSelector(state => state.singleContent.data);
  const loading = useSelector(state => state.singleContent.loading);

  const [truncatedContent, setTruncatedContent] = useState("");

  useEffect(() => {
    if (data?.single_content?.content) {
      // Create a temporary DOM element to parse the HTML
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = data.single_content.content;
      
      // Get the first three <p> elements and their text content
      const paragraphs = tempDiv.querySelectorAll('p');
      let content = "";
      for (let i = 0; i < 3 && i < paragraphs.length; i++) {
        content += paragraphs[i].textContent + " ";
      }

      // Remove HTML entities like &nbsp; by using textContent
      const decodedContent = content.replace(/\s+/g, ' ').trim();

      // Truncate the concatenated content to 100 characters
      const truncated = decodedContent.length > 100 ? decodedContent.substring(0, 200) + '...' : decodedContent;
      setTruncatedContent(truncated);
    }
  }, [data]);

  useEffect(() => {
    dispatch(getSingleContent(id));
  },[id])


  return (
    <div>
      <Helmet>
        <title>{data?.single_content?.title + " - Direktorat SMP"}</title>
        <meta name="description" content={truncatedContent} />
        <meta property="og:title" content={data?.single_content?.title + " - Direktorat SMP"} />
        <meta property="og:description" content={truncatedContent} />
        <meta property="og:image" content={data?.single_content?.image?.url} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
      </Helmet>
      <div className='pt-[70px]'>
      {
        !loading &&
          <div>
            <Jumbotron data={[{images: data?.single_content?.image?.url, title: data?.single_content?.title }]}/>
            <div className="md:grid md:grid-cols-3 md:px-20 px-6 md:py-20 py-6 md:gap-20">
              <div className="col-span-2 mb-10">
                  <div className="text-base sm:text-left text-justify mb-3 htmlPreview" dangerouslySetInnerHTML={{ __html: data?.single_content?.content }}></div>
              </div>
              <div className="col-span-1">
                  <InfoBar/>
              </div>
            </div>
          </div>
        }

    </div>
    </div>
    
  );
};

export default SingleContent;
