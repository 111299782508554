import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL

export const FETCH_INFO_REQUEST = 'FETCH_INFO_REQUEST';
export const FETCH_INFO_SUCCESS = 'FETCH_INFO_SUCCESS';
export const FETCH_INFO_FAILURE = 'FETCH_INFO_FAILURE';

export const fetchInfoRequest = () => ({
  type: FETCH_INFO_REQUEST,
});

export const fetchInfoSuccess = data => ({
  type: FETCH_INFO_SUCCESS,
  payload: data,
});

export const fetchInfoFailure = error => ({
  type: FETCH_INFO_FAILURE,
  payload: error,
});


// Async action creator using thunk
export const getInfo = (category, sort, page, search, sortBy) => {
  return dispatch => {
    dispatch(fetchInfoRequest());
    axios.get(`${BASE_URL}/ragaminformasi?category=${category}&sort=${sort}&page=${page}&search=${search}&sortBy=${sortBy ? sortBy : 'created_at'}`)
      .then(response => {
        const data = response.data;
        dispatch(fetchInfoSuccess(data));
      })
      .catch(error => {
        dispatch(fetchInfoFailure(error.message));
      });
  };
};

