import React, {useEffect, useState} from 'react';
import Jumbotron from '../../component/Jumbotron';
import InfoBar from '../../component/InfoBar';
import { useDispatch, useSelector } from 'react-redux';
import { getFileContent } from '../../store/actions/fileContentAction';
import { getFileListContent } from '../../store/actions/fileListContentAction';
import { useParams } from 'react-router-dom';
import { truncateText } from '../../utils/utils';
import { ArrowLeft, ArrowRight } from "@phosphor-icons/react";
import { CaretDown, CaretRight, CaretUp } from '@phosphor-icons/react/dist/ssr';
import PDFPreview from '../../component/PDFPreview';
import VideoPlayer from '../../component/VideoPlayer';
import AudioPlayer from '../../component/AudioPlayer';
import { Helmet } from 'react-helmet';

const FileContent = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { loading, data } = useSelector((state) => state.fileContent);
  const { loadingList, dataList } = useSelector((state) => state.fileList);
  const [list, setList] = useState([])
  const [collapse, setCollapse] = useState(null)

  const[filter, setFilter]=useState({
      page: 1,
      limit: 12,
      category: 0
  })

  useEffect(() => {
    dispatch(getFileContent(id));
    dispatch(getFileListContent(id));
  },[dispatch,id])

  const goToLinked = (pr) => {
    const _type = pr?.type;
    if(_type === 'image'){
      window.open(pr?.image_url, '_blank');        
    } else if(_type === 'link'){
      window.open(pr?.link, '_blank');        
    }else{
      window.open(pr?.attachment?.url, '_blank');        
    }
  }

  useEffect(() => {
    setList(dataList?.files?.slice(((filter.limit*filter.page)-filter.limit), (filter.limit*filter.page)))
  },[filter,dataList])

  const toggleCollapse = (idx) => {
    setCollapse(idx == collapse ? 0 : idx)
  }

  const [truncatedContent, setTruncatedContent] = useState("");

  useEffect(() => {
    if (data?.menu?.file_content?.content) {
      // Create a temporary DOM element to parse the HTML
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = data?.menu?.file_content?.content;
      
      // Get the first three <p> elements and their text content
      const paragraphs = tempDiv.querySelectorAll('p');
      let content = "";
      for (let i = 0; i < 3 && i < paragraphs.length; i++) {
        content += paragraphs[i].textContent + " ";
      }

      // Remove HTML entities like &nbsp; by using textContent
      const decodedContent = content.replace(/\s+/g, ' ').trim();

      // Truncate the concatenated content to 100 characters
      const truncated = decodedContent.length > 100 ? decodedContent.substring(0, 200) + '...' : decodedContent;
      setTruncatedContent(truncated);
    }
  }, [data]);


  return (
    <div>
      <Helmet>
        <title>{(data?.menu?.file_content?.title ?? data?.menu?.name) + " - Direktorat SMP"}</title>
        <meta name="description" content={truncatedContent} />
        <meta property="og:title" content={(data?.menu?.file_content?.title ?? data?.menu?.name) + " - Direktorat SMP"} />
        <meta property="og:description" content={truncatedContent} />
        <meta property="og:image" content={data?.menu?.file_content?.image?.url} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
      </Helmet>
      <div className='pt-[70px]'>
        {
          !loading &&
            <div>
              <Jumbotron data={[{images: data?.menu?.file_content?.image?.url, title: data?.menu?.file_content?.title }]}/>
              <div className="md:grid md:grid-cols-3 md:px-20 px-6 md:py-20 py-6 md:gap-20">
                <div className="col-span-2 mb-10">
                  <div className="text-base sm:text-left text-justify mb-8 htmlPreview" dangerouslySetInnerHTML={{  __html: dataList?.menu?.file_content?.content }}></div>

                  {/* <div className="md:grid grid-cols-3 mb-5 md:space-y-0 space-y-2">
                    <input type="text" placeholder="Cari..." className="col-span-1 col-start-1 border rounded-lg p-2.5 w-full"/>
                    <div className="col-span-1 col-start-3 flex items-center gap-2">
                      <div className="text-gray-700 flex-none hidden md:block">Urutkan : </div>
                      <select className="flex-auto border rounded-lg p-2.5">
                        <option value="">Terbaru</option>
                        <option value="">Terlama</option>
                      </select>
                    </div>
                  </div> */}

                  {dataList?.menu?.layout == 'list' ?
                    <div>
                      {!loadingList && list?.map((obj,key) => {
                        return (
                          <div key={key} data-accordion={collapse == obj.id} className='mb-3 border border-gray-200 rounded-lg'>
                            <h2 onClick={() => toggleCollapse(obj.id)}>
                              <button type="button" className="flex items-center justify-between w-full p-3 font-medium text-gray-800 gap-3">
                                <span>{ obj?.title }</span>
                                <div className='p-2'>
                                  {collapse == obj.id ?
                                    <CaretUp size={15}/> :
                                    <CaretDown size={15}/>
                                  }
                                </div>
                              </button>
                            </h2>
                            <div className={`${collapse == obj?.id ? 'block' : 'hidden'} text-gray-700 border-t border-gray-200`}>
                              <div className="p-5 border-gray-200">
                                <div className='mb-5'>
                                  { obj?.content }
                                </div>
                                {obj?.type == 'image' && <img src={obj?.attachment?.url} alt={obj?.title} />}
                                {(obj?.type == 'link' || obj?.type == 'document') && <button onClick={() => goToLinked(obj)} className='text-white bg-blue-700 hover:bg-blue-800 flex align-center px-5 py-2.5'><span>Lihat Detail</span> <CaretRight size={15}/></button>}
                                {obj?.type == 'pdf' && <PDFPreview pdfUrl={obj?.attachment?.url} />}
                                {obj?.type == 'video' && <VideoPlayer videoLink={obj?.attachment?.url}/>}
                                {obj?.type == 'audio' && <AudioPlayer audioLink={obj?.attachment?.url}/>}
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </div> :

                    <div className="md:grid md:grid-cols-4 gap-6">
                        {!loadingList && list?.map((obj,key) => {
                            return(
                                <div onClick={() => goToLinked(obj)} key={key} className='rounded-lg shadow-lg border border-[#eee] relative h-[280px] w-full items-center flex flex-col cursor-pointer hover:shadow-sm transition ease-in-out mb-3 md:mb-0 px-3'>
                                    <div className='relative w-full h-[140px] mb-3'>
                                      <img src={obj?.image_url} alt="Ragam Informasi" className='h-full w-full my-3 rounded-lg object-cover bg-[#518FB5]' />
                                      <div className="text-xs font-bold bg-[#016BA3] text-gray-100 px-3 py-1 absolute top-5 left-2 rounded-lg">{ obj?.type }</div>
                                    </div>
                                    <div className="py-2 w-full z-20">
                                        <div className="text-gray-800 font-bold break-words mb-1">{truncateText(obj?.title || '', 35)}</div>
                                        <div className="text-xs text-gray-800">{truncateText(obj?.content || '', 35)}</div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                  }
                  {!loadingList && dataList?.files?.length > 0 &&
                      <div className={`flex items-center justify-between w-full mt-8 py-8 border-t-2 border-[#eee] md:mt-20`}>
                          <div className={`flex-none flex items-center cursor-pointer ${filter.page === 1 ? 'hidden' : 'block'}`} onClick={() => setFilter(prevFilter => ({ ...prevFilter, page: filter.page-1 }))}>
                              <ArrowLeft size={20} color='#333' />
                              <span className="font-semibold ml-2">Sebelumnya</span>
                          </div>
                          <div className="flex-auto flex items-center justify-center gap-2">
                              {Array.from({ length: Math.ceil(dataList?.files?.length/filter.limit) }, (_, index) => (
                                  <div key={index} onClick={() => setFilter(prevFilter => ({ ...prevFilter, page: index+1 }))} className={`${filter.page === index+1 ? 'bg-[#F0F7FC] text-[#008DD1]' : 'bg-[#FFF] text-gray-800'} font-semibold w-12 h-12 rounded-sm flex items-center justify-center cursor-pointer hover:text-[#008DD1]`}>
                                      {index + 1}
                                  </div>
                              ))}
                          </div>
                          <div className={`flex-none flex items-center cursor-pointer ${filter.page === Math.ceil(dataList?.files?.length/filter.limit) ? 'hidden' : 'block'}`} onClick={() => setFilter(prevFilter => ({ ...prevFilter, page: filter.page+1 }))}>
                              <span className="font-semibold mr-2">Selanjutnya</span>
                              <ArrowRight size={20} color='#333' />
                          </div>
                      </div>
                  }
                </div>
                <div className="col-span-1">
                    <InfoBar/>
                </div>
              </div>
            </div>
          }

      </div>
    </div>
  );
};

export default FileContent;
