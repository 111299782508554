import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL

export const FETCH_ARTICLE_REQUEST = 'FETCH_ARTICLE_REQUEST';
export const FETCH_ARTICLE_SUCCESS = 'FETCH_ARTICLE_SUCCESS';
export const FETCH_ARTICLE_FAILURE = 'FETCH_ARTICLE_FAILURE';

export const fetchArticleRequest = () => ({
  type: FETCH_ARTICLE_REQUEST,
});

export const fetchArticleSuccess = data => ({
  type: FETCH_ARTICLE_SUCCESS,
  payload: data,
});

export const fetchArticleFailure = error => ({
  type: FETCH_ARTICLE_FAILURE,
  payload: error,
});


// Async action creator using thunk
export const getArticle = (category) => {
  return dispatch => {
    dispatch(fetchArticleRequest());
    axios.get(`${BASE_URL}/ragaminformasi/${category}`)
      .then(response => {
        const data = response.data.data;
        dispatch(fetchArticleSuccess(data));
      })
      .catch(error => {
        dispatch(fetchArticleFailure(error.message));
      });
  };
};

