import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL

export const FETCH_DATA_REQUEST = 'FETCH_DATA_REQUEST';
export const FETCH_DATA_SUCCESS = 'FETCH_DATA_SUCCESS';
export const FETCH_DATA_FAILURE = 'FETCH_DATA_FAILURE';

export const fetchDataRequest = () => ({
  type: FETCH_DATA_REQUEST,
});

export const fetchDataSuccess = data => ({
  type: FETCH_DATA_SUCCESS,
  payload: data,
});

export const fetchDataFailure = error => ({
  type: FETCH_DATA_FAILURE,
  payload: error,
});


// Async action creator using thunk
export const getSingleContent = (idx) => {
  return dispatch => {
    dispatch(fetchDataRequest());
    axios.get(`${BASE_URL}/content/single-content/${idx}`)
      .then(response => {
        const data = response.data.data;
        dispatch(fetchDataSuccess(data));
      })
      .catch(error => {
        dispatch(fetchDataFailure(error.message));
      });
  };
};
