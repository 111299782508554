import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL

export const FETCH_PENGADUAN_REQUEST = 'FETCH_PENGADUAN_REQUEST';
export const FETCH_PENGADUAN_SUCCESS = 'FETCH_PENGADUAN_SUCCESS';
export const FETCH_PENGADUAN_FAILURE = 'FETCH_PENGADUAN_FAILURE';

export const fetchPengaduanRequest = () => ({
  type: FETCH_PENGADUAN_REQUEST,
});

export const fetchPengaduanSuccess = data => ({
  type: FETCH_PENGADUAN_SUCCESS,
  payload: data,
});

export const fetchPengaduanFailure = error => ({
  type: FETCH_PENGADUAN_FAILURE,
  payload: error,
});


// Async action creator using thunk
export const postPengaduan = (data) => {
  return dispatch => {
    dispatch(fetchPengaduanRequest());
    axios.post(`${BASE_URL}/pengaduan`, data)
      .then(response => {
        const data = response.data.data;
        dispatch(fetchPengaduanSuccess(data));
      })
      .catch(error => {
        dispatch(fetchPengaduanFailure(error.message));
      });
  };
};

