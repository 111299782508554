export const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
        return `${text.slice(0, maxLength)}...`;
    }
    return text;
};

export const formatDate = (dateString) => {
    const dateObj = new Date(dateString);
    
    // Extract date components
    const day = dateObj.getDate().toString().padStart(2, '0'); // Add leading zero if needed
    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
    const year = dateObj.getFullYear();
    const hours = dateObj.getHours().toString().padStart(2, '0');
    const minutes = dateObj.getMinutes().toString().padStart(2, '0');
    
    // Create formatted date string
    const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}`;
    
    return formattedDate;
};

export const truncateHTML = (html, maxLength, addEllipsis = false) => {
  let truncated = "";
  let totalLength = 0;
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  let ellipsisAdded = false;

  const walk = (node) => {
    if (totalLength >= maxLength) return;

    if (node.nodeType === Node.TEXT_NODE) {
      const remainingLength = maxLength - totalLength;
      if (node.textContent.length > remainingLength) {
        truncated += node.textContent.slice(0, remainingLength);
        if (addEllipsis && !ellipsisAdded) {
          truncated += "...";
          ellipsisAdded = true;
        }
        totalLength += remainingLength;
      } else {
        truncated += node.textContent;
        totalLength += node.textContent.length;
      }
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      truncated += `<${node.nodeName.toLowerCase()}${Array.from(node.attributes).map(attr => ` ${attr.name}="${attr.value}"`).join("")}>`;

      node.childNodes.forEach(child => walk(child));

      truncated += `</${node.nodeName.toLowerCase()}>`;
    }
  };

  doc.body.childNodes.forEach(child => walk(child));

  return truncated;
};
