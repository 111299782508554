import React, { useEffect, useState} from 'react';
import { Outlet } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import CustomerService from './CustomerService';
import { getFooter } from '../store/actions/footerAction';
import { useDispatch, useSelector } from 'react-redux';
import { getGeneral } from '../store/actions/generalAction';

function Layout() {
  const dispatch = useDispatch();
  const dataFooter = useSelector(state => state.footer.data);
  const loadingFooter = useSelector(state => state.footer.loading);
  const dataGeneral = useSelector(state => state.general.data);
  const loadingGeneral = useSelector(state => state.general.loading);

  const [control, setControl] = useState(false)
  const [hideMenuMobile, setMenuMobile] = useState(false)
  const [active, setActive] = useState(null)
  const [open, setOpen] = useState(false)

  const openChat = (x) => {
    const iframe = document.querySelector('body #tiledesk-container iframe');
    const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;

    // Example: Query a button inside the <iframe>
    const buttonInsideIframe = iframeDocument.querySelector('#c21-launcher-button');
    
    if (buttonInsideIframe) {
      buttonInsideIframe.click();
      setOpen(false);
    }
  };

  useEffect(() => {
    dispatch(getFooter());
    dispatch(getGeneral());
  }, [dispatch]);

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = `
        window.tiledeskSettings = {
          projectid: "668dd150b186eb0013fe452b"
        };
        (function(d, s, id) {
            var w=window; var d=document; var i=function(){i.c(arguments);};
            i.q=[]; i.c=function(args){i.q.push(args);}; w.Tiledesk=i;
            var js, fjs=d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js=d.createElement(s);
            js.id=id; js.async=true; js.src="https://ditsmp.kemdikbud.go.id/widget/launch.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document,'script','tiledesk-jssdk'));
    `;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  },[])

  useEffect(() => {
    const link = document.querySelector("link[rel~='icon']");
    console.log(link)
    if (!link && !loadingGeneral) {
      const newLink = document.createElement('link');
      newLink.rel = 'icon';
      newLink.href = dataGeneral?.favicon?.[0]?.image?.url;
      document.head.appendChild(newLink);
    } else {
      link.href = dataGeneral?.favicon?.[0]?.image?.url;
    }
  },[dataGeneral])

  return (
    <div className='relative'>
        <Header general={dataGeneral} hideSub={control} setControl={setControl} hideMenuMobile={hideMenuMobile} setMenuMobile={setMenuMobile} active={active} setActive={setActive}/>
        <main className="min-h-screen overflow-x-hidden" 
          onClick={() => { 
            setControl(false); 
            setMenuMobile(false); 
            setActive(null);
            setOpen(false);
          }}>
            <Outlet />
        </main>
        <Footer data={dataFooter} general={dataGeneral}/>
        <CustomerService setOpen={setOpen} open={open} setOpenChat={openChat}/>
    </div>
  );
}

export default Layout;