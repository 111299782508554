import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BookmarkSimple, CaretLeft, CaretRight } from "@phosphor-icons/react";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Jumbotron = ({ data, isHome, childClass }) => {
    // console.log(data)
    const [slideActive, setSlideActive] = useState(0);
    useEffect(() => {
        const intervalId = setInterval(() => {
            setSlideActive(prevSlideActive => (prevSlideActive + 1) % data?.length);
        }, 10000);

        return () => clearInterval(intervalId); // Cleanup the interval on component unmount
    }, [data]);

    const handleNextSlide = () => {
        setSlideActive(prevSlideActive => (prevSlideActive + 1) % data.length);
    };

    const handlePrevSlide = () => {
        setSlideActive(prevSlideActive => (prevSlideActive - 1 + data.length) % data.length);
    };

    return (
        <div className={`h-fit relative ${childClass}`}>
            {isHome ?
                <div>
                    {data?.map((item,key)=>{
                        return(
                            <div className={`mt-[40px] absolute items-center justify-center md:h-screen h-[400px] w-full transition-opacity duration-700 ease-in ${slideActive === key  ? 'opacity-100 flex z-10':'opacity-0 flex z-0'}`}>
                                <LazyLoadImage
                                    alt={item.title}
                                    className="object-cover w-screen md:h-[calc(100%-70px)] h-[400px] filter absolute z-20"
                                    src={item.images}/>
                                <a href={`ragam-informasi/${item?.categoryObj?.slug}/${item?.slug}`} key={key} className="backdrop bg-[#00000095] absolute md:h-[calc(100%-70px)] h-[400px] w-screen z-50"></a>
                                <div className="absolute z-50 container md:w-4/5 w-full px-3 md:px-0 flex items-center justify-between gap-4">
                                    <div className="flex-none cursor-pointer hover:bg-[#cccccc88] bg-[#cccccc33] p-4" onClick={() => handlePrevSlide()}><CaretLeft size={32} color='#FFF'/></div>
                                    <a href={`ragam-informasi/${item?.categoryObj?.slug}/${item?.slug}`} className="flex-auto flex">
                                        <BookmarkSimple size={36} weight='fill' color='#F8CE06' className='mt-1 md:flex hidden'/>
                                        <div className="flex flex-col gap-2 ml-3">
                                            <div className="text-white sm:text-4xl text-xl h-fit md:max-w-[900px] font-bold">{item.title}</div>
                                            <div className="bg-[#016BA377] text-[#ccc] font-semibold px-3 py-1 text-sm uppercase w-fit rounded-xl">{item.category}</div>
                                            <div className="text-gray-100 text-base h-fit md:max-w-[900px] font-normal">
                                                Oleh <span className='font-semibold text-white uppercase'>{item.author}</span> {item.date}
                                            </div>
                                        </div>
                                    </a>
                                    <div className="flex-none cursor-pointer hover:bg-[#cccccc88] bg-[#cccccc33] p-4" onClick={() => handleNextSlide()}><CaretRight size={32} color='#FFF'/></div>
                                </div>
                                <div className="absolute w-fit bottom-20 flex gap-2 z-[999]">
                                    {data?.map((dot,index) => {
                                        return (
                                            <div onClick={() => setSlideActive(index)} key={index} className={`transition-all duration-300 ease-in-out ${index === slideActive ? 'w-8 bg-[#008DD1] border-[#008DD1]' : 'w-3 border-[#fff]'} h-3 border-2 rounded-full`}></div>
                                        )
                                    })}
                                </div>
                            </div>
                        )
                    })}
                </div> : 
                <div>
                    {data?.map((item,key)=>{
                        return(
                            <div key={key} className={`flex items-center justify-center relative h-[350px] w-full`}>
                                <LazyLoadImage
                                    alt={item.title}
                                    className="object-cover w-screen h-[350px]"
                                    src={item.images}/>
                                <div className="backdrop bg-[#016BA3AA] absolute h-[350px] w-screen z-10"></div>
                                <div className="absolute z-50 container md:w-2/3 w-full px-10 md:px-0">
                                    <div className="text-white text-5xl h-fit md:max-w-[900px] border-l-4 md:border-l-8 md:pl-8 pl-4 border-yellow-400 font-bold py-2">{item.title}</div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            }
        </div>
    );
};

export default Jumbotron;