import React, { useState, useRef } from 'react';

const VideoPlayer = ({ videoLink }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  const togglePlay = () => {
    const video = videoRef.current;
    setIsPlaying(!isPlaying);

    if (isPlaying) {
      video.pause();
    } else {
      video.play();
    }
  };

  return (
    <div>
      <video
        ref={videoRef}
        src={videoLink}
        controls
        onClick={togglePlay}
      />
      {/* <button onClick={togglePlay}>{isPlaying ? 'Pause' : 'Play'}</button> */}
    </div>
  );
};

export default VideoPlayer;
