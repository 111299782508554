import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL

export const FETCH_FILE_LIST_REQUEST = 'FETCH_FILE_LIST_REQUEST';
export const FETCH_FILE_LIST_SUCCESS = 'FETCH_FILE_LIST_SUCCESS';
export const FETCH_FILE_LIST_FAILURE = 'FETCH_FILE_LIST_FAILURE';

export const fetchFileListRequest = () => ({
  type: FETCH_FILE_LIST_REQUEST,
});

export const fetchFileListSuccess = data => ({
  type: FETCH_FILE_LIST_SUCCESS,
  payload: data,
});

export const fetchFileListFailure = error => ({
  type: FETCH_FILE_LIST_FAILURE,
  payload: error,
});


// Async action creator using thunk
export const getFileListContent = (idx) => {
  return dispatch => {
    dispatch(fetchFileListRequest());
    axios.get(`${BASE_URL}/content/file-content-file/${idx}`)
      .then(response => {
        const data = response.data.data;
        dispatch(fetchFileListSuccess(data));
      })
      .catch(error => {
        dispatch(fetchFileListFailure(error.message));
      });
  };
};
