// App.js
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './component/Layout';
import RagamInfo from './pages/ragam-info/Index';
import RagamInfoDetail from './pages/ragam-info/Detail';
import Home from './pages/Home';
import SingleContent from './pages/single-content/Index';
import FileContent from './pages/file-content/Index';
import PengaduanMasyarakat from './pages/pengaduan-masyarakat/Index';
import Link from './pages/link/Index';
import ReactGA from 'react-ga4';
import NotFound from './pages/app-handler/Notfound';
import TrackPageView from './utils/TrackPageView';

function App() {
  useEffect(() => {
    // Initialize GA4
    ReactGA.initialize('G-962HM6Q5WL', { debug: false });
  }, []);

  return (
    <Router basename={process.env.REACT_APP_BASENAME}>
      <TrackPageView></TrackPageView>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/single-content/:id" element={<SingleContent />}/>
          <Route path="/file-content/:id" element={<FileContent />}/>
          <Route path="/ragam-informasi/:id" element={<RagamInfo />} />
          <Route path="/ragam-informasi/:id/:slug" element={<RagamInfoDetail />} />
          <Route path="/pengaduan-masyarakat" element={<PengaduanMasyarakat />} />
          <Route path="/link/:id" element={<Link />} />
        </Route>
        <Route path="*" element={<NotFound/>} />
      </Routes>
    </Router>
  );
}

export default App;