// src/reducers/pengaduan.js
import {
  FETCH_PENGADUAN_REQUEST,
  FETCH_PENGADUAN_SUCCESS,
  FETCH_PENGADUAN_FAILURE,
} from '../actions/pengaduanAction';

const initialState = {
  loading: false,
  data: [],
  error: ''
};

const pengaduanReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PENGADUAN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_PENGADUAN_SUCCESS:
      return {
        loading: false,
        data: action.payload,
        error: '',
      };
    case FETCH_PENGADUAN_FAILURE:
      return {
        loading: false,
        data: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export default pengaduanReducer;
