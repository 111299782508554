import React, {useState, useEffect} from 'react';
import { Clock } from "@phosphor-icons/react";
import dummyInfo from '../asset/dummy/_info';
import { truncateText, formatDate, truncateHTML } from '../utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { getInfo } from '../store/actions/infoAction';
import { useNavigate } from 'react-router-dom';

const InfoBar = () => {
    const dispatch = useDispatch();
    const data = useSelector(state => state.info.data);
    const loading = useSelector(state => state.info.loading);
    const navigate = useNavigate()

    const [tab, setTab] = useState('article')
    const infoTab = [
      {id:'article', label:'Artikel', menuId: 96}, 
      {id:'news', label:'Berita', menuId:97}, 
      {id:'story', label:'Berbagi Cerita', menuId:98}
    ]

    useEffect(() => {
      dispatch(getInfo(tab, 'desc', 1, ''));
    },[tab])

    return (
        <div className="bg-[#F0F7FC] p-6">
          <div className="py-1 w-fit text-xl text-gray-800 border-b-4 border-yellow-400 mb-3 font-bold">Ragam Informasi</div>
          <div className="bg-[#F0F7FC] rounded-full py-2 flex gap-1 mb-2">
              {infoTab.map((item, key) => {
              return(
                  <div key={key} className={`rounded-full px-3 py-1 text-sm cursor-pointer ${tab === item?.id ? 'bg-white text-[#008DD1] font-bold' : 'text-[#85AAD1] font-normal'}`} onClick={() => setTab(item?.id)}>
                      {item.label}
                  </div>
              )
              })}
          </div>
          {!loading &&
            <>
              {data?.data?.length > 0 && data?.data.slice(0,4).map((item, key) => {
                return(
                  <div onClick={() => navigate(`/ragam-informasi/${infoTab.filter((a) => a.id == item.category?.title)[0]?.id}/${item.slug}`)} 
                    key={key} 
                    className="border-collapse border border-slate-100 rounded-lg flex flex-col shadow-lg items-center gap-3 mb-4 cursor-pointer hover:shadow-sm transition ease-in-out"
                  >
                    <div className="">
                      <img src={item?.featured_image?.url} alt="" className='aspect-[32/15] rounded-t-lg object-cover bg-gray-300' />
                    </div>
                    <div className="gap-y-3 md:pr-2 h-full p-2">
                      <div className="flex flex-col h-full justify-between">
                        <div className='mb-3'>
                          <div className="font-bold">{truncateText(item?.title || '', 60)}</div>
                          <div className="text-xs text-gray-800" dangerouslySetInnerHTML={{  __html:truncateHTML(item?.content, 70, true)}}></div>
                        </div>
                        <div>
                          <div className="font-normal text-xs mb-1">Oleh <span className="font-semibold text-xs uppercase">{item?.author?.name}</span></div>
                          <div className="text-gray-800 flex gap-1">
                            <Clock size={20} color="#008DD1"/>
                            <div className="text-semibold text-xs text-gray-800">{formatDate(item?.created_at)}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </>
          }
      </div>
    );
};

export default InfoBar;