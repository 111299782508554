import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CaretDown, CaretUp, MagnifyingGlass } from "@phosphor-icons/react";
import { useSelector, useDispatch } from 'react-redux';
import { getLink, getMenu } from '../store/actions/menuAction';
import logo from '../asset/images/logo.png';

const Header = ({general, hideSub, setControl, hideMenuMobile, setMenuMobile, active, setActive }) => {
    const dispatch = useDispatch();
    const data = useSelector(state => state.menu.data);
    const loading = useSelector(state => state.menu.loading);
    const basePath = process.env.REACT_APP_BASENAME || '';

    const [submenu, setSubmenu] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        dispatch(getMenu());
    }, []);

    const toggleChild = (val) => {
        console.log(val)
        const list = data.filter((a) => a.parent_id == val.id);
        if(list.length > 0 || val.type === 'parent'){
            setSubmenu(list)
            setControl(true)
        }else{
            directPage(val);
        }
    }

    const directPage = (p) => {
        console.log(p)
        if(p.type === 'link-content'){
            dispatch(getLink(p.slug)).then(data => {
                window.open(data?.link_content?.url, '_blank');
            })
        }else if(p.type === 'self-content'){
            navigate(`${p.slug}`);
        }else{
            setControl(false)
            setActive(null)
            navigate(`${p.type}/${p.slug}`);
        }
    }

    const unBindToggle = (key) => {
        if(active === key){
            setControl(false)
            setActive(null)
        }else{
            setActive(key)
        }
    }

    const getActive = (x) => {
        const _act = data.filter((a) => a.parent_id == x)[0];
        console.log(_act)
        if(_act == active){
            return true
        } else {
            return false
        }
    }


    return (
        <header className={`fixed w-full z-[999999]`}>
            <nav className="bg-[#016BA3] border-gray-200 px-4 lg:px-16 min-h-[80px] flex items-center w-full justify-between">
                <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-2xl relative w-full">
                    {general?.logo_header?.[0]?.image?.url &&
                        <a href={basePath} className="flex items-center">
                            <img src={general?.logo_header?.[0]?.image?.url} alt="logo" className="mb-2 h-[50px]"/>
                        </a>
                    }
                    <div className="md:hidden flex items-center lg:order-2">
                        <button type="button" onClick={() => setMenuMobile(true)} className="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
                            <span className="sr-only">Open main menu</span>
                            <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path></svg>
                            <svg className="hidden w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </button>
                    </div>
                    <div className={`justify-between items-center w-full lg:flex lg:w-auto lg:order-1 ${hideMenuMobile ? 'flex mt-2' : 'hidden'}`}>
                        <ul className="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0">
                            {data.length > 0 && data.map((item,key) => {
                                return(
                                    <li key={key} className={`${item?.parent_id == null ? '' : 'hidden'}`}>
                                        <div onClick={() => {toggleChild(item);unBindToggle(item?.slug)}} className="cursor-pointer block py-2 pr-4 pl-3 text-white text-sm rounded bg-primary-700 lg:bg-transparent lg:text-primary-700 lg:p-0 dark:text-white flex gap-2 items-center md:hover:scale-110 transition ease-in-out">
                                            {item?.name}

                                            {item?.type == 'parent' &&     
                                            <span>
                                                {active !== item?.id ?
                                                    <CaretDown color="#FFF" size={20} /> : <CaretUp color="#FFF" size={20} />
                                                }
                                            </span>
                                            }
                                        </div>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
            </nav>

            <div className={`grid grid-rows-4 grid-flow-col gap-4 p-5 bg-white min-h-[0px] shadow-lg ${!hideSub ? 'hidden' : ''}`}>
                {submenu.map((item,key)=>{
                    return(
                        <div key={key} onClick={() => directPage(item)} className={`${() => getActive(item?.parent_id) ? '' : 'hidden'} text-[#016BA3] border-l-4 pl-2 border-white hover:border-[#016BA3] hover:duration-300 cursor-pointer`}>
                            {item.name}
                        </div>
                    )
                })}
            </div>
        </header>
    );
};

export default Header;
