import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL
const dedicated = [
  {
      "id": 995,
      "name": "Ragam Informasi",
      "slug": "ragam-informasi",
      "type": "parent",
      "parent_id": null,
      "status": "active",
      "order": 4,
      "deleted_at": null,
      "created_at": "2024-06-18T03:17:16.000000Z",
      "updated_at": "2024-06-18T03:17:16.000000Z"
  },
  {
      "id": 996,
      "name": "Berita",
      "slug": "news",
      "type": "ragam-informasi",
      "parent_id": 995,
      "status": "active",
      "order": 1,
      "deleted_at": null,
      "created_at": "2024-06-18T03:21:26.000000Z",
      "updated_at": "2024-06-18T03:21:26.000000Z"
  },
  {
      "id": 997,
      "name": "Artikel",
      "slug": "article",
      "type": "ragam-informasi",
      "parent_id": 995,
      "status": "active",
      "order": 2,
      "deleted_at": null,
      "created_at": "2024-06-18T03:21:26.000000Z",
      "updated_at": "2024-06-18T03:21:26.000000Z"
  },
  {
      "id": 998,
      "name": "Berbagi Cerita",
      "slug": "story",
      "type": "ragam-informasi",
      "parent_id": 995,
      "status": "active",
      "order": 3,
      "deleted_at": null,
      "created_at": "2024-06-18T03:21:26.000000Z",
      "updated_at": "2024-06-18T03:21:26.000000Z"
  }
]


export const FETCH_MENU_REQUEST = 'FETCH_MENU_REQUEST';
export const FETCH_MENU_SUCCESS = 'FETCH_MENU_SUCCESS';
export const FETCH_MENU_FAILURE = 'FETCH_MENU_FAILURE';

export const fetchMenuRequest = () => ({
  type: FETCH_MENU_REQUEST,
});

export const fetchMenuSuccess = data => ({
  type: FETCH_MENU_SUCCESS,
  payload: data,
});

export const fetchMenuFailure = error => ({
  type: FETCH_MENU_FAILURE,
  payload: error,
});


// Async action creator using thunk
export const getMenu = () => {
  return dispatch => {
    dispatch(fetchMenuRequest());
    axios.get(`${BASE_URL}/menu`)
      .then(response => {
        const data = response.data.data;
        const list = data.concat(dedicated)
        dispatch(fetchMenuSuccess(list));
      })
      .catch(error => {
        dispatch(fetchMenuFailure(error.message));
      });
  };
};

export const getLink = (id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
    axios.get(`${BASE_URL}/content/link-content/${id}`)
      .then(response => {
        resolve(response.data.data);
      })
      .catch(error => {
        reject(error);
      });
    });
  };
};
