import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL

export const FETCH_FILE_REQUEST = 'FETCH_FILE_REQUEST';
export const FETCH_FILE_SUCCESS = 'FETCH_FILE_SUCCESS';
export const FETCH_FILE_FAILURE = 'FETCH_FILE_FAILURE';

export const fetchFileRequest = () => ({
  type: FETCH_FILE_REQUEST,
});

export const fetchFileSuccess = data => ({
  type: FETCH_FILE_SUCCESS,
  payload: data,
});

export const fetchFileFailure = error => ({
  type: FETCH_FILE_FAILURE,
  payload: error,
});


// Async action creator using thunk
export const getFileContent = (idx) => {
  return dispatch => {
    dispatch(fetchFileRequest());
    axios.get(`${BASE_URL}/content/file-content/${idx}`)
      .then(response => {
        const data = response.data.data;
        dispatch(fetchFileSuccess(data));
      })
      .catch(error => {
        dispatch(fetchFileFailure(error.message));
      });
  };
};
