import React from 'react';

const PDFPreview = ({ pdfUrl }) => {
  return (
    <div className="pdf-preview-container">
      <object
        className="pdf-preview"
        data={pdfUrl}
        type="application/pdf"
        width="100%"
        height="500px"
      >
        <p>
          Your browser does not support PDFs. Please download the PDF to view
          it.
        </p>
      </object>
    </div>
  );
};

export default PDFPreview;
