// src/reducers/index.js
import { combineReducers } from 'redux';
import menuReducer from './menuReducer';
import singleContentReducer from './singleContentReducer';
import infoReducer from './infoReducer';
import articleReducer from './articleReducer';
import fileContentReducer from './fileContentReducer';
import fileListContentReducer from './fileListContentReducer';
import pengaduanReducer from './pengaduanReducer';
import footerReducer from './footerReducer';
import generalReducer from './generalReducer';

const rootReducer = combineReducers({
  menu: menuReducer,
  singleContent: singleContentReducer,
  info: infoReducer,
  article: articleReducer,
  fileContent: fileContentReducer,
  fileList: fileListContentReducer,
  pengaduan: pengaduanReducer,
  footer: footerReducer,
  general: generalReducer
});

export default rootReducer;
