// src/reducers/info.js
import {
    FETCH_ARTICLE_REQUEST,
    FETCH_ARTICLE_SUCCESS,
    FETCH_ARTICLE_FAILURE,
  } from '../actions/articleAction';
  
  const initialState = {
    loading: false,
    data: [],
    error: ''
  };
  
  const articleReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_ARTICLE_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case FETCH_ARTICLE_SUCCESS:
        return {
          loading: false,
          data: action.payload,
          error: '',
        };
      case FETCH_ARTICLE_FAILURE:
        return {
          loading: false,
          data: [],
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default articleReducer;
  