import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL

export const FETCH_GENERAL_REQUEST = 'FETCH_GENERAL_REQUEST';
export const FETCH_GENERAL_SUCCESS = 'FETCH_GENERAL_SUCCESS';
export const FETCH_GENERAL_FAILURE = 'FETCH_GENERAL_FAILURE';

export const fetchGeneralRequest = () => ({
  type: FETCH_GENERAL_REQUEST,
});

export const fetchGeneralSuccess = data => ({
  type: FETCH_GENERAL_SUCCESS,
  payload: data,
});

export const fetchGeneralFailure = error => ({
  type: FETCH_GENERAL_FAILURE,
  payload: error,
});


// Async action creator using thunk
export const getGeneral = () => {
  return dispatch => {
    dispatch(fetchGeneralRequest());
    axios.get(`${BASE_URL}/generalsetting`)
      .then(response => {
        const data = response.data;
        dispatch(fetchGeneralSuccess(data));
      })
      .catch(error => {
        dispatch(fetchGeneralFailure(error.message));
      });
  };
};

