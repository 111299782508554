// backup for stater

import React from 'react';
import map from '../asset/images/map.png';

const Statistics = ({open, setOpen}) => {
    return (
        <div className="mb-8 sm:h-[700px] h-[400px] w-screen relative flex justify-center items-center">
            <img src={map} alt='map' className='absolute w-full h-full z-10'/>
            <div className="sm:grid sm:grid-cols-2 md:w-2/3 md:px-0 px-6 w-full z-20 items-center">
                <div className="col-span-1 md:mb-1 mb-5">
                <div className="md:w-96">
                    <div className="md:text-4xl text-2xl text-[#008DD1] font-bold">Data Sekolah <br /> Menengah Pertama <br /> di Indonesia</div>
                    <div className="text-base text-yellow-600 font-bold">17 Februrai 2024</div>
                </div>
                </div>
                <div className="col-span-1 md:mb-1 mb-5 grid grid-cols-2 gap-x-4 md:gap-y-10 gap-y-4">
                <div className="md:text-4xl text-2xl text-[#008DD1] font-bold sm:flex flex-col items-center justify-center">
                    <div className="text-gray-800 text-base font-bold">Peserta Didik</div>
                    <span>125.103</span>
                </div>
                <div className="md:text-4xl text-2xl text-[#008DD1] font-bold sm:flex flex-col items-center justify-center">
                    <div className="text-gray-800 text-base font-bold">Satuan Pendidikan</div>
                    <span>103</span>
                </div>
                <div className="md:text-4xl text-2xl text-[#008DD1] font-bold sm:flex flex-col items-center justify-center">
                    <div className="text-gray-800 text-base font-bold">Pendidik</div>
                    <span>203</span>
                </div>
                <div className="md:text-4xl text-2xl text-[#008DD1] font-bold sm:flex flex-col items-center justify-center">
                    <div className="text-gray-800 text-base font-bold">Rombongan Belajar</div>
                    <span>125.103</span>
                </div>
                </div>
            </div>
        </div>
    );
};

export default Statistics;